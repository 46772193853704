<template>
  <v-row>
    <v-col cols="12" md="8">
        <!-- <v-row> -->
            <!-- <v-col cols="12" md="12"> -->
                <v-card class="">
                    <v-card-title>
                    {{$t('О компании')}}
                    </v-card-title>
                    <v-card-text>
                    <p>ООО «ФОРВАРД»</p>
                    <ul style="list-style-type: none; padding-left: 0;">
                        <li>ИНН:7840069723</li>
                        <li>КПП: 784001001</li>
                        <li>ОГРН: 1177847293766</li>
                        <li>ОКПО: 19401799</li>
                        <br>
                        <li>Расчетный счет: 40702810355000064638</li>
                        <li>Банк: СЕВЕРО-ЗАПАДНЫЙ БАНК ПАО СБЕРБАНК</li>
                        <li>БИК: 044030653</li>
                        <li>Корр. счет: 30101810500000000653</li>
                        <br>
                        <li>Юридический адрес: 191025, Санкт-Петербург г, Марата ул, дом 10, литер А, помещение 8Н</li>
                        <br>
                        <li>{{$t('Email:')}}&nbsp;<a href="mailto:info@avangard-travel.ru">info@avangard-travel.ru</a></li>
                        <li>{{$t('Телефон:')}}&nbsp;<a href="tel:(812) 640-02-28">(812) 640-02-28</a></li>
                    </ul>
                    </v-card-text>
                </v-card>
            <!-- </v-col> -->
        <!-- </v-row> -->
    </v-col>
    <v-col cols="12" md="4" class="mx-0 my-0 px-0 py-0"></v-col>

    <v-col cols="12" md="4">
        <v-card class="">
            <v-card-title>{{$t('Договор')}}</v-card-title>
            <v-card-text>
            Вы можете посмотреть договор с ООО «ФОРВАРД»
            </v-card-text>
            <v-card-actions class="justify-start">
            <v-btn
                outlined
                color="primary"
                class="ps-4"
                href="https://backrequests.atg-service.info/static/%D0%94%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D1%80.pdf"
                target="_blank"
            >
                {{$t('Открыть')}}
                <v-icon
                dark
                right
                >
                {{ icons.mdiOpenInNew }}
                </v-icon>
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
    <v-col cols="12" md="4">
        <v-card class="">
            <v-card-title>{{$t('Договор оферты')}}</v-card-title>
            <v-card-text>
                Вы можете посмотреть договор оферты с ООО «ФОРВАРД»
            </v-card-text>
            <v-card-actions class="justify-start">
            <v-btn
                outlined
                color="primary"
                class="ps-4"
                href="https://backrequests.atg-service.info/static/%D0%94%D0%9E%D0%93%D0%9E%D0%92%D0%9E%D0%A0_%D0%9E%D1%84%D0%B5%D1%80%D1%82%D0%B0.pdf"
                target="_blank"
            >
            {{$t('Открыть')}}
                <v-icon
                dark
                right
                >
                {{ icons.mdiOpenInNew }}
                </v-icon>
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
    <v-col cols="12" md="8">
        <v-card>
            <v-card-title>{{$t('Политика возврата')}}</v-card-title>
            <v-card-text>
                {{$t('Вы можете посмотреть политику возврата.')}}
            </v-card-text>
            <v-card-actions class="justify-start">
            <v-btn
                outlined
                color="primary"
                class="ps-4"
                href="https://backrequests.atg-service.info/static/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%D0%92%D0%BE%D0%B7%D0%B2%D1%80%D0%B0%D1%82%D0%B0.pdf"
                target="_blank"
            >
            {{$t('Открыть')}}
                <v-icon
                dark
                right
                >
                {{ icons.mdiOpenInNew }}
                </v-icon>
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
  </v-row>
</template>


<script>
import { mdiOpenInNew } from '@mdi/js'

export default {
    setup() {
        return {
            icons: {
                mdiOpenInNew,
            },
        }
    },
}
</script>
